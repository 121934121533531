<template>
  <div class="bottom">
    <el-row class="public-width width-auto box box0">

      <el-col :md="18">
        <div class="msg-list">
          <p>北京启迪邦妙文体科技有限公司</p>
          <p>联系电话：13908715880</p>
          <p>电子邮箱：tusparkchuyu@163.com</p>          
          <p>
            地址：北京市延庆区中关村延庆园东环路2号楼1659室
          </p>
<!--            
          <p >
            北京启迪邦妙文体科技有限公司 版权所有 Copyright ©2022  All Right Reserved <a href="https://beian.miit.gov.cn/">  京ICP备2022010216号-1</a>	
          </p> -->
           </div>
          <div class="msg-list msg-list0">
          <p>
            友情链接： <a href="https://www.zgggw.gov.cn">中国关工委</a>
            <a href="https://www.bsu.edu.cn/">北京体育大学</a>
             <a href="http://rugby.sport.org.cn/">中国橄榄球协会</a>
              <a href="http://www.tuscity.com/">启迪科技城集团</a>
              <a href="https://www.tusstar.com/">启迪之星</a>
          </p>
        </div>
      </el-col>


      <el-col :md="4">
        <div class="msg-list msg-list2">
          <h3>关注启迪邦妙公众号</h3>
          <el-image :src="require('@/assets/qr.jpg')" class="wx-img"></el-image>
        </div>
      </el-col>      
      <el-col :md="4">
        <div class="msg-list msg-list2">
          <h3>启迪春雨微信小程序</h3>
          <el-image :src="require('@/assets/qrmp.jpg')" class="wx-img"></el-image>
        </div>
      </el-col>
      <el-col :md="4">
        <div class="msg-list msg-list2"> 
          <h3>启迪春雨安卓APP</h3>
          <el-image :src="require('@/assets/qrapp.png')" class="wx-img"></el-image>
        </div>
      </el-col>
    </el-row>
  <el-row class="public-width width-auto box">
      <el-col  >
        <div class="msg-list msg-list3" style="text-align:center">
         
          <p >
            北京启迪邦妙文体科技有限公司 版权所有 Copyright ©2022  All Right Reserved <a href="https://beian.miit.gov.cn/">  京ICP备2022010216号-1</a>	
          </p>
       
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  methods: {
     
  },
};
</script>
<style lang="less" scoped>
.bottom {
  background-color: rgb(24, 24, 24);
  padding: 25px 0 25px 0;
  .box{
    display: flex;
     align-items: center;
     margin-bottom: 5px;
  }
}
.wx-img {
  width: 110px;
  height: 110px;
  border: 3px solid rgb(139, 139, 139);
}
.msg-list {
  text-align: left;
   display: flex;
   flex-direction: column;
   justify-content: center;
  h3 {
    color: rgb(197, 197, 197);
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 10px;
    cursor: pointer;
  }
  p {
    font-size: 14px;
    color: rgb(197, 197, 197);
    margin: 5px 0;
    cursor: pointer;
    a{
    color: rgb(197, 197, 197);
  }
  }
  
}
.msg-list2 {
  text-align: center;
  justify-content: center;
  align-items: center;
}
.icp {
  color: rgb(148, 148, 148);
  font-size: 13px;
}
.msg-list0{
  margin-top: 10px;
   
  p {
    font-size: 14px;
    color: rgb(156, 154, 154);
    margin: 5px 0;
    cursor: pointer;
     
    a{
      margin-right: 15px;
    color: rgb(156, 154, 154);
  }
  }
}
.msg-list3{
  padding-top: 5px;
  border-top: 1px solid rgb(77, 77, 77);
  p {
    font-size: 14px;
    color: rgb(156, 154, 154);
    margin: 5px 0;
    cursor: pointer;
    a{
    color: rgb(156, 154, 154);
  }
  }
}
</style>